import { render, staticRenderFns } from "./AddAppointment.vue?vue&type=template&id=475e93af&scoped=true"
import script from "./AddAppointment.vue?vue&type=script&lang=js"
export * from "./AddAppointment.vue?vue&type=script&lang=js"
import style0 from "./AddAppointment.vue?vue&type=style&index=0&id=475e93af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475e93af",
  null
  
)

export default component.exports